import { Loader } from "@googlemaps/js-api-loader";
import Decimal from "decimal.js";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import { CustomerAddress as CustomerAddressType, CustomerDetails } from "@megaron/crm-contracts";
import { DescriptionField } from "@megaron/dash-description-list";
import { useDialogRoute } from "@megaron/dash-dialog";
import { GoogleMap } from "@megaron/dash-google-map";
import { SectionHeader } from "@megaron/dash-page";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";

import { EditCustomerAddressDialog } from "./EditCustomerAddressDialog";

const googleMapsLoader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

type Props = {
  customer: CustomerDetails;
  queryKey: string | string[];
};

export const CustomerAddress: React.FC<Props> = ({ customer, queryKey }) => {
  const queryClient = useQueryClient();
  const updateCustomer = useClientManager("crm").saveCustomer().useMutation();

  const toast = useToast();

  const [address, setAddress] = useState<CustomerAddressType>(customer.address);

  const getLatLng = async (street: string, postalCode: string): Promise<[Decimal, Decimal] | [null, null]> => {
    const address = `${street}, ${postalCode}`;

    try {
      console.log("Loader options:", googleMapsLoader.options);

      await googleMapsLoader.load();

      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
            const location = results[0].geometry.location;
            resolve([new Decimal(location.lat()), new Decimal(location.lng())]);
          } else {
            resolve([null, null]);
          }
        });
      });
    } catch (error) {
      console.error("Error loading Google Maps API:", error);
      return [null, null];
    }
  };

  const updateCustomerMutation = useCallback(async () => {
    let updatedAddress = { ...address };

    if (address.street || address.postalCode) {
      const [latitude, longitude] = await getLatLng(address.street, address.postalCode);

      updatedAddress = { ...address, latitude, longitude };
    }

    updateCustomer.mutate(
      {
        uuid: customer.uuid,
        address: updatedAddress,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          toast.info("Dane klienta zostały zaktualizowane");
        },
        onError: () => {
          toast.error("Nie udało się zaktualizować danych klienta");
        },
      },
    );
  }, [updateCustomer, customer.uuid, address, queryClient, queryKey, toast]);

  const editAddressDialog = useDialogRoute(`/edit-address`, ({ onClose }) => (
    <EditCustomerAddressDialog
      onClose={onClose}
      address={address}
      onAddressChange={setAddress}
      onSave={updateCustomerMutation}
      isLoading={updateCustomer.isLoading}
    />
  ));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader isHr>Adres</SectionHeader>

      <button
        onClick={editAddressDialog.open}
        css={{ background: "none", border: "none", textAlign: "start", cursor: "pointer", padding: 0 }}
      >
        <DescriptionField label="Adres">
          {Object.values(address).some(Boolean)
            ? `${address.street} ${address.city} ${address.country} ${address.postalCode}`
            : "-"}
        </DescriptionField>
      </button>

      {address.latitude && address.longitude && (
        <GoogleMap latLng={{ latitude: address.latitude, longitude: address.longitude }} />
      )}

      {editAddressDialog.element}
    </div>
  );
};
