import { tags } from "@megaron/crm-contracts";
import { SelectDialog } from "@megaron/dash-select";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  customerId: Uuid;
  customerTags: string[];
  onSuccess: () => void;
  onClose: () => void;
};
export const EditTagsDialog: React.FC<Props> = (props) => {
  const saveTags = useClientManager("crm").saveCustomer().useMutation();

  const saveCustomerTags = (tags: string[]) => {
    saveTags.mutate(
      {
        tags,
        uuid: props.customerId,
      },
      {
        onSuccess: (r) => {
          props.onSuccess();
        },
      },
    );
  };

  return (
    <SelectDialog
      variant="multi-select"
      noTrigger
      initiallySelectedValues={props.customerTags}
      onClose={props.onClose}
      options={tags.map((tag) => ({ label: `#${tag}`, value: tag }))}
      onSelectedChange={(tags) => saveCustomerTags(tags.map((tag) => tag.value))}
      isOpen
      shouldNotCloseOnSelect
    />
  );
};
